<template>
  <div class="flex justify-center">
    <div class="text-center py-10">
      <p class="font-semibold text-5xl">Diese Seite existiert nicht.</p>
      <br>
      <div @click="$router.go(-1)">
        <fmu-button button-type="outline">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 float-left">
            <path fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"/>
          </svg>
          zurück
        </fmu-button>
      </div>
    </div>
  </div>
</template>

<script>
import FmuButton from "@/components/theme/button/FmuButton";

export default {
  name: '404',
  components: {FmuButton}
}
</script>

<style scoped>
</style>
